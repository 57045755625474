<template>
    <div>
        <ContactForm/>
    </div>
</template>

<script>
// @ is an alias to /src
import ContactForm from "@/components/Contact/ContactForm.vue"




export default {
  name: 'ContactView',
  components: {
    ContactForm
  }
}
</script>
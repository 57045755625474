<template>
    <div class="px-6 py-12 pb-20 border-b-2 mb-12 mx-8 text-center md:px-12 lg:text-left">
      <div class="w-100 mx-auto sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl xl:px-32">
        <div class="grid items-center lg:grid-cols-2">
          <div class="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
            <div
              class="block rounded-lg bg-[hsla(0,0%,100%,1)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0)] dark:shadow-black/20 md:px-12 lg:-mr-14 backdrop-blur-[30px]"
            >
              <h1 class="mt-2 mb-16 text-xl font-bold tracking-tight md:text-2xl xl:text-3xl">
                More than just a tech company but <br /><span class="text-red-700 md:text-5xl xl:text-6xl">The architects of digital evolution</span>
              </h1>

              
            </div>
          </div>
          <div class="md:mb-12 lg:mb-0">
            <!-- src="https://tecdn.b-cdn.net/img/new/ecommerce/vertical/004.jpg" -->
            <img
              src="../../assets/AboutInfo.jpg"
              class="w-full rounded-lg shadow-lg dark:shadow-black/20 h-screen"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </template>
<template>
    <div class=" bg-neutral-200 px-6 py-12 text-center md:px-12 lg:text-left">
      <div class="w-100 mx-auto sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <div class="grid items-center gap-12 lg:grid-cols-2">
          <div class="mt-12 lg:mt-0">
            <h1 class="mt-0 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl text-red-700">
              Goals and Objectives<br />
            </h1>
            <span class="text-slate-800 text-xl font-bold">We aim to revolutionize the digital landscape, exceeding client expectations, fostering creativity, and forging lasting partnerships through a commitment to technological excellence.
            </span>
            <br>
            <RouterLink
              class="mb-2 mt-8 inline-block rounded bg-slate-400 px-12 pt-4 pb-3.5 text-base font-semibold uppercase leading-normal text-neutral-800 shadow-[0_4px_9px_-4px_#cbcbcb] transition duration-150 ease-in-out border hover:bg-slate-300 hover:border-black hover:border-x-2 hover:shadow-[0_8px_9px_-4px_rgba(203,203,203,0.3),0_4px_18px_0_rgba(203,203,203,0.2)] focus:bg-slate-300 focus:shadow-[0_8px_9px_-4px_rgba(203,203,203,0.3),0_4px_18px_0_rgba(203,203,203,0.2)] focus:outline-none focus:ring-0 active:bg-slate-300 active:shadow-[0_8px_9px_-4px_rgba(203,203,203,0.3),0_4px_18px_0_rgba(203,203,203,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(251,251,251,0.3)] dark:hover:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.1),0_4px_18px_0_rgba(251,251,251,0.05)] dark:focus:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.1),0_4px_18px_0_rgba(251,251,251,0.05)] dark:active:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.1),0_4px_18px_0_rgba(251,251,251,0.05)] md:mr-2 md:mb-0"
              data-te-ripple-init data-te-ripple-color="light" href="#!" role="button" to="/contact"
            >
              Contact Us
          </RouterLink>
            
          </div>
          <div class="mb-12 lg:mb-0">
            <img
              src="../../assets/Goals2.jpg"
              class="w-full rounded-lg shadow-lg dark:shadow-black/20"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </template>

<style scoped>
    .background-radial-gradient {
      
        /* background-color: hsl(0, 41%, 15%); 
        background-image: radial-gradient(650px circle at 0% 0%,
        hsl(0, 41%, 35%) 15%,
        hsl(0, 41%, 30%) 35%,
        hsl(0, 41%, 20%) 75%,
        hsl(0, 41%, 19%) 80%,
        transparent 100%),
      radial-gradient(1250px circle at 100% 100%,
        hsl(0, 41%, 45%) 15%,
        hsl(0, 41%, 30%) 35%,
        hsl(0, 41%, 20%) 75%,
        hsl(0, 41%, 19%) 80%,
        transparent 100%); */
        /* background-color: hsl(0, 41%, 25%); 
    background-image: radial-gradient(650px circle at 0% 0%,
        hsl(0, 41%, 45%) 15%,
        hsl(0, 41%, 40%) 35%,
        hsl(0, 41%, 30%) 75%,
        hsl(0, 41%, 29%) 80%,
        transparent 100%),
      radial-gradient(1250px circle at 100% 100%,
        hsl(0, 41%, 55%) 15%,
        hsl(0, 41%, 40%) 35%,
        hsl(0, 41%, 30%) 75%,
        hsl(0, 41%, 29%) 80%,
        transparent 100%); */
        background-color: #bb1a0a; 
    background-image: radial-gradient(650px circle at 0% 0%,
        hsl(0, 41%, 45%) 15%,
        hsl(0, 41%, 40%) 35%,
        hsl(0, 41%, 30%) 75%,
        hsl(0, 41%, 29%) 80%,
        transparent 100%),
      radial-gradient(1250px circle at 100% 100%,
        hsl(0, 41%, 55%) 15%,
        hsl(0, 41%, 40%) 35%,
        hsl(0, 41%, 30%) 75%,
        hsl(0, 41%, 29%) 80%,
        transparent 100%);
    }
</style>
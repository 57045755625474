<template>
    <div>
        <Dropdown/>
    </div>
</template>

<script>

import Dropdown from "@/components/TermAndCondition/DropDownOptions.vue"


export default {
  name: 'TermAndConditionView',
  components: {
    Dropdown
  }
}
</script>
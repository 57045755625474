<template>
    <div class="bg-neutral-200 px-6 py-12 text-center md:px-12 lg:py-24 lg:text-left -z-10">
        <div class="w-100 mx-auto text-neutral-800 sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl -z-10 ">
            <div class="grid items-center gap-12 lg:grid-cols-2">
                <div class="mt-12 lg:mt-0" >
                    <h1
                        class="mt-0 mb-12 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl text-slate-700">
                        Contact us<br /><span class="text-red-800">Here</span>
                    </h1>
                    <p class="text-xl font-semibold text-slate-700">
                        Feel free to reach out to us in case of any queries.
                    </p>
                </div>
                <div class="relative mb-12 lg:mb-0">
                    <div id="radius-shape-1" class="absolute rounded-full shadow-lg"></div>
                    <div id="radius-shape-2" class="absolute shadow-lg"></div>
                    <div
                        
                        class="relative bg-[hsla(0,0%,100%,0.9)] backdrop-blur-[25px] backdrop-saturate-[200%] block rounded-lg px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-red-800 dark:shadow-black/20 md:px-12">
                        <!-- dark:bg-[#d37474] -->
                        <form @submit.prevent="sendEmail">
                            
                            <div class="grid md:grid-cols-2 md:gap-6">
                                <div class="relative mb-6" data-te-input-wrapper-init>
                                    <label class="text-slate-200" for="exampleFormControlInput1"
                                        >First
                                        name
                                    </label>
                                    <input v-model="firstName" type="text" 
                                        class="bg-neutral-200 peer block min-h-[auto] w-full rounded border-0 py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-slate-600 dark:placeholder:text-slate-600 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                        id="exampleFormControlInput1" placeholder="First name" />
                                        <!-- class=" pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-slate-600 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-slate-800 font-semibold dark:peer-focus:text-primary" -->
                                </div>
                                <div class="relative mb-6" data-te-input-wrapper-init>
                                    <label class="text-slate-200" for="exampleFormControlInput2"
                                        >Last
                                        name
                                    </label>
                                    <input v-model="lastName" type="text"
                                        class="bg-neutral-200 peer block min-h-[auto] w-full rounded border-0 py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-slate-600 dark:placeholder:text-slate-600 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                        id="exampleFormControlInput2" placeholder="Last name" />
                                    <!-- <label for="exampleFormControlInput2"
                                        class="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-slate-800 font-semibold dark:peer-focus:text-primary">Last
                                        name
                                    </label> -->
                                </div>
                            </div>
                            <div class="relative mb-6" data-te-input-wrapper-init>
                                <label class="text-slate-200" for="exampleFormControlInput3">
                                    Email Address
                                </label>
                                <input v-model="email" type="email"
                                    class="bg-neutral-200 peer block min-h-[auto] w-full rounded border-0 py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-slate-600 dark:placeholder:text-slate-600 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleFormControlInput3" placeholder="Email address" />
                                <!-- <label for="exampleFormControlInput3"
                                    class="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-slate-800 font-semibold dark:peer-focus:text-primary">Email
                                    address
                                </label> -->
                            </div>
                            <div class="relative mb-6" data-te-input-wrapper-init>
                                <label class="text-slate-200" for="exampleFormControlInput4">
                                        Enter Your Text Here
                                </label>
                                <textarea v-model="message" rows="6"
                                    class="bg-neutral-200 peer block min-h-[auto] w-full rounded border-0 py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-slate-600 dark:placeholder:text-slate-600 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleFormControlInput4" placeholder="Enter Your text here"></textarea>
                                <!-- <label for="exampleFormControlInput4"
                                    class="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-slate-800 font-semibold dark:peer-focus:text-primary">Enter
                                    your text here
                                </label> -->
                            </div>

                            <!-- Button -->
                            <button type="submit"  data-te-ripple-init data-te-ripple-color="light"
                                class="bg-green-800 mb-6 inline-block w-full rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-900 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                                Send
                            </button>

                           
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



// import emailjs from 'emailjs-com';

// export default {
//     methods: {
//         async sendEmail() {
//             const templateParams = {
//                 firstName: this.firstName,
//                 lastName: this.lastName,
//                 email: this.email,
//                 message: this.message,
//             };

//             try {
//                 const response = await emailjs.send(
//                     'service_1m8odch',
//                     'template_vkvlizc',
//                     templateParams,
//                     'your_user_id'
//                 );

//                 console.log('Email sent successfully:', response);

//                 // Add any additional logic after a successful email send
//             } catch (error) {
//                 console.error('Error sending email:', error);
//             }
//         },
//     },
// };

<script>
import emailjs from 'emailjs-com';

export default {
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            message: '',
        };
    },
    methods: {
        async sendEmail() {
            try {
                const response = await emailjs.send(
                    'service_osr4ovo', // Replace with your Service ID
                    'template_apcje67', // Replace with your Template ID
                    {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        message: this.message,
                    },
                    '7VbpSpVrhuALDzpZ7' // Replace with your Public Key
                );

                console.log('Email sent successfully:', response);
                alert('Message sent successfully');
                // Add any additional logic after a successful email send
            } catch (error) {
                console.error('Error sending email:', error);
            }
        },
    },
};
</script>



<style scoped>
.background-radial-gradient {
    background-color: #bb1a0a;
    background-image: radial-gradient(650px circle at 0% 0%,
            hsl(0, 41%, 45%) 15%,
            hsl(0, 41%, 40%) 35%,
            hsl(0, 41%, 30%) 75%,
            hsl(0, 41%, 29%) 80%,
            transparent 100%),
        radial-gradient(1250px circle at 100% 100%,
            hsl(0, 41%, 55%) 15%,
            hsl(0, 41%, 40%) 35%,
            hsl(0, 41%, 30%) 75%,
            hsl(0, 41%, 29%) 80%,
            transparent 100%);
}

</style>
<template>
    <div>
        <ServiceContent/>
    </div>
</template>

<script>

import ServiceContent from "@/components/Service/ServiceContent.vue"


export default {
  name: 'ServiceView',
  components: {
     ServiceContent
  }
}
</script>
<template>
    <div class="container my-24 mx-auto md:px-6">
        <section class="mb-32">
            <h2 class="mb-16 text-center text-3xl text-red-700 font-bold">Our Services</h2>

            <!--==================================== 1st service======================================== -->

            <section id="AudioTranscription" class="mb-16 pb-16 flex flex-wrap items-center border-b-2">
                <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
                    <div class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg bg-black dark:shadow-black/20"
                        data-te-ripple-init data-te-ripple-color="light">
                        <img src="../../assets/AudioTranscription.png" class=" opacity-80  w-full"
                            alt="Louvre" />

                    </div>
                </div>

                <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
                    <h3 class="mb-4 text-2xl font-bold">Audio Transcription</h3>

                    <p class="mb-6 text-slate-800 dark:text-slate-800 text-left">
                        In the dynamic realm of content, audio transcription emerges as a pivotal service bridging spoken
                        words with written precision. Whether it's capturing insightful interviews, preserving important
                        meetings, or converting podcasts into readable content, our audio transcription service excels in
                        transforming spoken language into a written form with meticulous accuracy.
                    </p>
                    <h3 class="mb-4 text-2xl font-bold">Why Choose Our Audio Transcription Service?</h3>
                    <div class="text-slate-800 dark:text-slate-800 text-left">
                        <ol class="list-decimal">
                            <li><strong>Accuracy Unparalleled:</strong> Our skilled transcribers leverage advanced tools and
                                keen attention to detail, ensuring that every nuance, tone, and pause is faithfully
                                represented in the transcribed text.</li>
                            <li><strong>Multifaceted Capabilities:</strong> From business meetings and academic lectures to
                                podcast episodes and market research interviews, we cater to diverse industries, delivering
                                bespoke transcription solutions tailored to your unique needs.</li>
                            <li><strong>Timeliness Matters:</strong> We understand the importance of time in your projects.
                                Our commitment to prompt delivery ensures that your transcriptions are in your hands
                                precisely when you need them.</li>
                            <li><strong>Security at the Core:</strong> Your audio files are treated with the utmost
                                confidentiality. Our secure processes and strict privacy measures guarantee the protection
                                of your sensitive information.</li>
                        </ol>
                    </div>
                </div>
            </section>

            <!--==================================== 2nd service======================================== -->

            <section id="AudioDataCollection" class="mb-16 pb-16 flex flex-wrap lg:flex-row-reverse items-center border-b-2">
                <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
                    <div class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg bg-black dark:shadow-black/20"
                        data-te-ripple-init data-te-ripple-color="light">
                        <img src="../../assets/AudioDataCollection.jpg" class=" opacity-80 w-full"
                            alt="Louvre" />

                    </div>
                </div>

                <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
                    <h3 class="mb-4 text-2xl font-bold">Audio Data Collection</h3>

                    <p class="mb-6 text-slate-800 dark:text-slate-800 text-left">
                        Unlocking the potential of data begins with a nuanced understanding of its sources. In the realm of
                        information gathering, audio data stands as a rich and often untapped resource. Our Audio Data
                        Collection service empowers businesses and researchers to delve into the valuable insights embedded
                        in sound waves.
                    </p>
                    <h3 class="mb-4 text-2xl font-bold">Key Features of Audio Data Collection:</h3>
                    <div class="text-slate-800 dark:text-slate-800 text-left">
                        <ol class="list-decimal">
                            <li><strong>Diverse Applications:</strong> Whether you're exploring customer sentiment through
                                call center interactions or conducting environmental sound analysis, our audio data
                                collection service adapts to a myriad of applications.</li>
                            <li><strong>Customized Solutions:</strong> Tailored to your specific needs, our approach ensures
                                that the data collected aligns seamlessly with your objectives. From targeted industry
                                research to specialized project requirements, we've got you covered.</li>
                            <li><strong>Advanced Analytics:</strong> Beyond mere collection, we delve into the analytics.
                                Our experts employ cutting-edge tools to extract meaningful patterns and trends from the
                                audio data, providing you with actionable insights.</li>
                            <li><strong>Scalability:</strong> As your data needs evolve, our service scales with you.
                                Whether you're dealing with a small-scale project or a large-scale initiative, our flexible
                                solutions accommodate your growth.</li>
                        </ol>
                    </div>
                </div>
            </section>

            <!--==================================== 3rd service======================================== -->

            <section id="DataEvaluation" class="mb-16 pb-16 flex flex-wrap items-center border-b-2">
                <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
                    <div class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg bg-black dark:shadow-black/20"
                        data-te-ripple-init data-te-ripple-color="light">
                        <img src="../../assets/DataEvaluation.jpg" class=" opacity-80  w-full"
                            alt="Louvre" />

                    </div>
                </div>

                <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
                    <h3 class="mb-4 text-2xl font-bold">Data Evaluation</h3>

                    <p class="mb-6 text-slate-800 dark:text-slate-800 text-left">
                        IIn the era of information overload, the ability to extract meaningful insights from data is a game-changer. Our Data Evaluation service goes beyond raw numbers, offering a comprehensive analysis that transforms data into actionable intelligence.
                    </p>
                    <h3 class="mb-4 text-2xl font-bold">Navigating the Landscape of Data Evaluation</h3>
                    <div class="text-slate-800 dark:text-slate-800 text-left">
                        <ol class="list-decimal">
                            <li><strong>Holistic Assessment:</strong> We don't just analyze data; we conduct a holistic evaluation. From the reliability of sources to the relevance of variables, our process ensures a thorough understanding of the data landscape.</li>

                            <li><strong>Customized Metrics:</strong> Recognizing the uniqueness of each project, our experts tailor evaluation metrics to align with your specific goals. This personalized approach guarantees that the insights generated are directly applicable to your decision-making processes.</li>

                            <li><strong>Predictive Modeling:</strong> Beyond retrospective analysis, our service incorporates predictive modeling techniques. Anticipate trends, forecast outcomes, and stay ahead of the curve with data-driven foresight.</li>

                            <li><strong>Interactive Reporting:</strong> We don't just deliver insights; we present them in a user-friendly, interactive format. Our reports are designed to facilitate easy interpretation, fostering a deeper understanding of the implications behind the numbers.</li>
                        </ol>
                    </div>
                </div>
            </section>
        
            <!--==================================== 4th service======================================== -->

            <section id="DocumentTranslation" class="mb-16 pb-16 flex flex-wrap lg:flex-row-reverse items-center border-b-2">
                <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
                    <div class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg bg-black dark:shadow-black/20"
                        data-te-ripple-init data-te-ripple-color="light">
                        <img src="../../assets/DocumentTranslation.png" class=" opacity-80 w-full"
                            alt="Louvre" />

                    </div>
                </div>

                <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
                    <h3 class="mb-4 text-2xl font-bold">Document Translation</h3>

                    <p class="mb-6 text-slate-800 dark:text-slate-800 text-left">
                        In a globally interconnected landscape, effective communication knows no borders. Our Document Translation service serves as the linguistic bridge, enabling seamless transfer of information across languages and cultures.
                    </p>
                    <h3 class="mb-4 text-2xl font-bold">Embracing the Essence of Document Translation:</h3>
                    <div class="text-slate-800 dark:text-slate-800 text-left">
                        <ol class="list-decimal">
                            <li><strong>Language Expertise:</strong> Our team of skilled linguists possesses not only proficiency in multiple languages but also a deep understanding of cultural nuances. This ensures that your documents are not just translated but effectively convey the intended meaning in the target language.</li>

                            <li><strong>Diverse Document Types:</strong> Whether it's legal documents, technical manuals, marketing materials, or academic papers, our translation service spans a diverse range of document types. Each translation is executed with precision, maintaining the integrity and clarity of the original content.</li>

                            <li><strong>Quality Assurance:</strong>Rigorous quality checks are embedded in our translation process. From linguistic accuracy to cultural appropriateness, we uphold the highest standards to deliver translations that resonate authentically with the target audience.</li>

                            <li><strong>Timely Delivery:</strong> Recognizing the time-sensitive nature of many projects, our commitment to punctuality ensures that your translated documents are delivered within agreed-upon timelines without compromising quality.</li>
                        </ol>
                    </div>
                </div>
            </section>

            <!--==================================== 5th service ======================================== -->

            <section id="VideoTranslation" class="mb-16 pb-10 flex flex-wrap items-center ">
                <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
                    <div class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg bg-black dark:shadow-black/20"
                        data-te-ripple-init data-te-ripple-color="light">
                        <img src="../../assets/VideoTranslation.png" class=" opacity-80  w-full"
                            alt="Louvre" />

                    </div>
                </div>

                <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
                    <h3 class="mb-4 text-2xl font-bold">Video Translation</h3>

                    <p class="mb-6 text-slate-800 dark:text-slate-800 text-left">
                        As visuals dominate the digital landscape, the need for Video Translation becomes paramount in reaching diverse audiences. Our service seamlessly transforms video content, ensuring that the message resonates across linguistic and cultural boundaries.
                    </p>
                    <h3 class="mb-4 text-2xl font-bold">Navigating the World of Video Translation:</h3>
                    <div class="text-slate-800 dark:text-slate-800 text-left">
                        <ol class="list-decimal">
                            <li><strong>Multilingual Subtitling:</strong> Elevate your videos with accurate and contextually relevant subtitles. Our experts meticulously craft subtitles that not only convey the spoken words but also capture the essence of the message.</li>

                            <li><strong>Dubbing Expertise:</strong> For a more immersive experience, our video translation service includes professional dubbing. Whether it's for educational videos, marketing content, or entertainment, we ensure that the voiceover aligns seamlessly with the tone and intent of the original.</li>

                            <li><strong>Cultural Adaptation:</strong>Beyond linguistic translation, our approach includes cultural adaptation. We tailor the content to resonate with the cultural nuances of the target audience, ensuring that your message is not just understood but genuinely connects.</li>

                            <li><strong>Technical Precision:</strong> From lip synchronization in dubbing to timing accuracy in subtitling, our video translation service is characterized by technical precision. Your audience experiences a seamless viewing experience in their preferred language.</li>
                        </ol>
                    </div>
                </div>
            </section>

    </section>
</div>
</template>

<script>
export default {
    // ... other component options

    // mounted() {
    //     // Check if there's a scroll pending
    //     if (this.$root.scrollPending && this.$route.hash) {
    //         // Reset the scrollPending flag
    //         this.$root.scrollPending = false;

    //         // Wait for the DOM to be fully updated before scrolling
    //         this.$nextTick(() => {
    //             // Scroll to the section defined by the hash in the route
    //             const targetElement = document.querySelector(this.$route.hash);
    //             if (targetElement) {
    //                 targetElement.scrollIntoView({ behavior: 'smooth' });
    //             }
    //         });
    //         this.$root.scrollPending = true;
    //     }
    // },
    beforeMount() {
        // Check if there's a scroll pending
        if (this.$root.scrollPending && this.$route.hash) {
            // Reset the scrollPending flag
            this.$root.scrollPending = false;

            // Wait for the DOM to be fully updated before scrolling
            this.$nextTick(() => {
                // Scroll to the section defined by the hash in the route
                const targetElement = document.querySelector(this.$route.hash);
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            });
        }
    },
};




</script>

<template>
    <div class="hero-container relative overflow-hidden bg-cover bg-no-repeat top-0" style="
      background-position: 50%; 
      height: 550px;
    ">
        <div class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsla(0,0%,0%,0.3)] bg-fixed">
            <div class="flex h-full items-center justify-center z-10">
                <div class="px-6 text-center text-slate-100  md:px-12">
                    <h1 class="mt-2 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl text-center">
                       Welcome to the realm of innovation<br>
                       <span class="text-slate-300 md:text-2xl xl:text-3xl text-center">where online projects come to life through cutting-edge solutions.</span>
                    </h1>
                    <Button type="button"
                      class="rounded border-2 border-neutral-50 px-[46px] pt-[14px] pb-[12px] text-sm font-semibold uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-100 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200" @click="navigateToServices" 
                      data-te-ripple-init data-te-ripple-color="light" >
                        Services
                    </Button>
                    
                </div>
            </div>
        </div>
    </div>

  </template>



<style scoped>
.hero-container {
  background-image: url('~@/assets/HeroImage.jpg');
}
</style>

<script>
export default {
  methods: {
    navigateToServices() {
      this.$router.push('/services');
    },
  },
};
</script>
<template>
    <div class="container my-24 mx-auto md:px-6 xl:px-24 ">
        <section class="mb-32 ">
            <h2 class="mb-6 pl-6 text-3xl font-bold text-red-800">Terms And Conditions</h2>

            <div id="accordionFlushExample " class="border rounded-2xl bg-neutral-100">
                <div class="rounded-none border border-t-0 border-l-0 border-r-0 border-neutral-200"
                    v-for="(faq, index) in faqs" :key="index">
                    <h2 class="mb-0" :id="'flush-heading' + (index + 1)">
                        <button
                            class="group relative flex w-full items-center rounded-none border-0 py-4 px-5 text-left text-base font-bold transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:text-primary-400"
                            type="button" data-te-collapse-init :data-te-target="'#flush-collapse' + (index + 1)"
                            :aria-expanded="faq.expanded.toString()" :aria-controls="'flush-collapse' + (index + 1)"
                            @click="toggleCollapse(index)">
                            
                            {{ index+1 + '.' + ' ' +  faq.question }}
                            <span
                                class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#ec3333] transition-transform duration-200 ease-in-out group-[[faq.collapsed]]:rotate-0 group-[[faq.collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-[#e08f8f] dark:group-[[faq.collapsed]]:fill-[#eee]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </span>
                        </button>
                    </h2>
                    <div v-if="faq.expanded == true" :id="'flush-collapse' + (index + 1)" class="!visible " data-te-collapse-item
                        :data-te-collapse-show="faq.expanded" :aria-labelledby="'flush-heading' + (index + 1)"
                        data-te-parent="#accordionFlushExample">
                        <div class="py-4 px-5 text-slate-500 dark:text-slate-500 font-semibold">{{ faq.answer }}</div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            faqs: [
                {
                    question: 'Introduction',
                    answer: 'Welcome to Cerea Intelligence! These terms and conditions outline the rules and regulations for the use of cereaintelligence.ai',
                    expanded: false,
                },
                {
                    question: 'Intellectual Property Rights',
                    answer: 'All content on this website is the property of Cerea Intelligence and is protected by intellectual property laws.',
                    expanded: false,
                },
                {
                    question: 'User Responsibilities',
                    answer: 'Users must comply with applicable laws and regulations.They are responsible for maintaining the confidentiality of their account information.',
                    expanded: false,
                },
                {
                    question: 'Prohibited Activities',
                    answer: 'Users must not engage in activities that violate laws, infringe on intellectual property rights, or disrupt the website\'s functionality.',
                    expanded: false,
                },
                {
                    question: 'Privacy Policy',
                    answer: 'Our privacy policy outlines how we collect, use, and protect your personal information.',
                    expanded: false,
                },
                {
                    question: 'Termination',
                    answer: 'We reserve the right to terminate user accounts for violations of these terms.',
                    expanded: false,
                },
                {
                    question: 'Limitation of Liability',
                    answer: 'Cerea Intelligence is not liable for any direct or indirect damages arising from the use of this website.',
                    expanded: false,
                },
                {
                    question: 'Changes to Terms',
                    answer: 'We reserve the right to modify these terms at any time. Users are responsible for reviewing them regularly.',
                    expanded: false,
                },
                {
                    question: 'Governing Law',
                    answer: 'These terms are governed by and construed in accordance with the laws of Pakistan.',
                    expanded: false,
                },
                {
                    question: 'Contact Information',
                    answer: 'For questions or concerns about these terms, please contact us at queries@cereaintelligence.ai',
                    expanded: false,
                },
            ],
        };
    },
    methods: {
        toggleCollapse(index) {
            this.faqs[index].expanded = !this.faqs[index].expanded;
        },
    },
};
</script>
  
<template>
  <div class="home">
    <HeroSection/>
    <FactsSection/>
    <GoalsSection/>
    <WhyUsSection/>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from "@/components/Home/HeroSection.vue"
import FactsSection from "@/components/Home/FactsSection.vue"
import WhyUsSection from "@/components/Home/WhyUsSection.vue"
import GoalsSection from "@/components/Home/GoalsSection.vue"




export default {
  name: 'HomeView',
  components: {
    HeroSection,
    FactsSection,
    WhyUsSection,
    GoalsSection
  }
}
</script>

<template>
    <div class=" text-white bg-[hsla(0,0%,0%,0.95)]  bottom-0 w-full py-8 flex lg:flex-row lg:justify-around  md:flex-col md:justify-center md:items-center sm:flex-col sm:justify-center sm:items-center ">
      <div class=" w-2/5 flex flex-col justify-center items-center">
        <p>&copy; {{ Year }} CereaIntelligence. All rights reserved.</p>
        <p>Email: queries@cereaintelligence.ai</p>
        <img class="h-20 pt-3" src="../assets/logo2-.png" alt="Logo">
      </div>
      <div class=" w-3/5 list-none flex lg:flex-row lg:justify-around lg:items-center md:flex-col md:justify-center md:items-center sm:flex-col sm:justify-center sm:items-center ">
        <div class=" flex flex-col lg:items-start md:items-center sm:items-center space-y-1 underline underline-offset-4">
            <RouterLink to="/services">Services</RouterLink>
            <RouterLink to="/about">About Us</RouterLink>
            <RouterLink to="/privacypolicy">Privacy Policy</RouterLink>
            <RouterLink to="/termandcondition">Terms and Conditions</RouterLink>
        </div>
        <div class=" flex flex-col items-center lg:mr-20 space-y-2 underline underline-offset-4">
            <RouterLink to="/contact">Contact Us</RouterLink>
            <div class="flex space-x-2 ">
                <img src="../assets/InstaLogo.png " alt="Instagram">
                <img src="../assets/FBLogo.png " alt="Facebooks">
                
                <img src="../assets/TwitterLogo.png " alt="Twitter">
            </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      Year: new Date().getFullYear(),
    };
  },
};
</script>
<template>
  <div class="about">
    <AboutInfo/>
    <!-- <MeetTeam/> -->
    <ProjectsDone/>
  </div>
</template>

<script>
// @ is an alias to /src
// import MeetTeam from "@/components/AboutUs/TeamSection.vue"
import AboutInfo from "@/components/AboutUs/AboutInfo.vue"
import ProjectsDone from "@/components/AboutUs/ProjectsDone.vue"



export default {
  name: 'HomeView',
  components: {
    // MeetTeam,
    AboutInfo,
    ProjectsDone
  }
}
</script>

<template>
    <div class="container my-24 mx-auto md:px-6 xl:px-24">
        <section class="mb-32">
            <h2 class="mb-6 pl-6 text-3xl font-bold text-red-800">Privacy Policy</h2>

            <div id="accordionFlushExample" class="border rounded-2xl bg-neutral-100">
                <div class="rounded-none border border-t-0 border-l-0 border-r-0 border-neutral-200"
                    v-for="(faq, index) in faqs" :key="index">
                    <h2 class="mb-0" :id="'flush-heading' + (index + 1)">
                        <button
                            class="group relative flex w-full items-center rounded-none border-0 py-4 px-5 text-left text-base font-bold transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:text-primary-400"
                            type="button" data-te-collapse-init :data-te-target="'#flush-collapse' + (index + 1)"
                            :aria-expanded="faq.expanded.toString()" :aria-controls="'flush-collapse' + (index + 1)"
                            @click="toggleCollapse(index)">
                            
                            {{ index+1 + '.' + ' ' +  faq.question }}
                            <span
                                class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[faq.collapsed]]:rotate-0 group-[[faq.collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-[#8FAEE0] dark:group-[[faq.collapsed]]:fill-[#eee]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </span>
                        </button>
                    </h2>
                    <div v-if="faq.expanded == true" :id="'flush-collapse' + (index + 1)" class="!visible " data-te-collapse-item
                        :data-te-collapse-show="faq.expanded" :aria-labelledby="'flush-heading' + (index + 1)"
                        data-te-parent="#accordionFlushExample">
                        <div class="py-4 px-5 text-slate-500 dark:text-slate-500 font-semibold">{{ faq.answer }}</div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            faqs: [
                {
                    question: 'Introduction',
                    answer: 'This Privacy Policy outlines how Cerea Intelligence collects, uses, and protects your personal information when you use cereaintelligence.ai',
                    expanded: false,
                },
                {
                    question: 'Information We Collect',
                    answer: 'We may collect personal information such as name, email, and address when you register or use our services.',
                    expanded: false,
                },
                {
                    question: 'How We Use Your Information',
                    answer: 'We use your information to provide and improve our services, communicate with you, and personalize your experience.',
                    expanded: false,
                },
                {
                    question: 'Information Sharing',
                    answer: 'We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this policy.',
                    expanded: false,
                },
                {
                    question: 'Cookies and Tracking Technologies',
                    answer: 'We use cookies and similar technologies to enhance your experience, analyze usage, and customize content.',
                    expanded: false,
                },
                {
                    question: 'Security',
                    answer: 'We implement security measures to protect your personal information, but no method of transmission over the internet is entirely secure.',
                    expanded: false,
                },
                {
                    question: 'Your Choices',
                    answer: 'You can control the collection and use of certain information by adjusting your browser settings or opting out where applicable.',
                    expanded: false,
                },
                {
                    question: 'Third-Party Links',
                    answer: 'Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of these sites.',
                    expanded: false,
                },
                {
                    question: 'Children\'s Privacy',
                    answer: 'Our services are not intended for individuals under the age of 12. We do not knowingly collect personal information from children.',
                    expanded: false,
                },
                {
                    question: 'Changes to Privacy Policy',
                    answer: 'We may update this Privacy Policy, and any changes will be posted on this page.',
                    expanded: false,
                },
                {
                    question: 'Contact Information',
                    answer: 'For questions or concerns about this Privacy Policy, please contact us at queries@cereaintelligence.ai',
                    expanded: false,
                }
            ],
        };
    },
    methods: {
        toggleCollapse(index) {
            this.faqs[index].expanded = !this.faqs[index].expanded;
        },
    },
};
</script>
  
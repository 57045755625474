<template>
    <div>
        <PrivacyPolicyDropdown/>
    </div>
</template>

<script>

import PrivacyPolicyDropdown from "@/components/PrivacyPolicy/PrivacyPolicyOptions.vue"


export default {
  name: 'PrivacyPolicyView',
  components: {
    PrivacyPolicyDropdown
  }
}
</script>
<template>
  <!-- <div class="flex flex-col items-center justify-center my-20 ">
    <h2 class="mb-6 p-6 text-3xl font-bold text-red-800">Projects Done So Far</h2>
    <ul class=" w-3/6 grid  grid-cols-3 border"> -->
  <div class="flex flex-col items-center justify-center my-20">
    <h2 class="mb-6 p-6 text-3xl font-bold text-red-800">Projects Done So Far</h2>
    <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 border">
      <li class="w-full border py-4 dark:border-opacity-50">
        Georgian Project
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Icelandic
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Thai
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Afrikaans
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Norwegian
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Finnish
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Dutch
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Nepali
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Malay
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Newar
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Persian
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Bikol
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Serbian
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Lithuanian
      </li>
      <li class="w-full  border py-4 dark:border-opacity-50">
        Slovak
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Hungarian
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Mazenderani
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Nduae Zimbabwe
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Gilaki
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Fulfulde
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Zulu
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Czech
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Punjabi (India)
      </li>
      <li class="w-full border py-4 dark:border-opacity-50">
        Kannauji
      </li>
    </ul>
  </div>
</template>
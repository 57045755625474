<template>
    <div
        class="flex lg:flex-row md:flex-col sm:flex-col px-5 py-3 sticky top-0 bg-[hsla(0,0%,0%,0.95)]  text-white justify-between z-10">
        <div
            class="flex lg:justify-center md:justify-between sm:justify-between items-center lg:w-2/6 md:w-auto sm:w-auto ">
            <img class="h-20 ml-12" src="../assets/logo2-.png" alt="Logo">
            <button @click="enableNavbar" class="lg:hidden">
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                        clip-rule="evenodd"></path>
                </svg>
            </button>
        </div>
        <div class=" lg:w-4/6 md:w-auto sm:w-auto flex lg:flex-row md:flex-col sm:flex-col justify-around items-center list-none"
            v-show="isNavbarEnabled">
            <RouterLink @click="enableNavbar" class="hover:underline hover:underline-offset-4 py-4"
                :class="{ 'underline underline-offset-4': $route.path === '/' }" to="/">Home</RouterLink>

            <!-- basis-[100%] lg:!flex  hidden-->
            <div class="!visible items-center flex lg:basis-auto" id="navbarSupportedContentX" data-te-collapse-item>
                <ul class="mr-auto flex flex-row" data-te-navbar-nav-ref>
                    <li class="static" data-te-nav-item-ref data-te-dropdown-ref>
                        <a :class="{ 'underline underline-offset-4': $route.path === '/services' }"
                            class="flex items-center whitespace-nowrap py-2 px-2 text-white transition duration-300 ease-in-out focus:text-white "
                            href="#" data-te-ripple-init data-te-ripple-color="light" type="button" id="dropdownMenuButtonX"
                            @click.prevent="toggleDropdown" :aria-expanded="isDropdownOpen.toString()" data-te-nav-link-ref>
                            Services
                            <span class="ml-2 w-2">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down"
                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path fill="currentColor"
                                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z">
                                    </path>
                                </svg>
                            </span>
                        </a>
                        <ul class="absolute z-[1000] float-left m-0 mt-1 w-[300px] list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700"
                            :class="{ 'hidden': !isDropdownOpen }" :aria-labelledby="'dropdownMenuButtonX'"
                            data-te-dropdown-menu-ref>
                            <li v-for="(item, index) in dropdownItems" :key="index">

                                <a class="block w-full whitespace-normal bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 transition duration-300 ease-in-out hover:bg-neutral-50 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                    :href="item.Asect" @click="enableNavbar"
                                    @click.prevent="handleDropdownItemClick(item, $event)" data-te-dropdown-item-ref>
                                    <p class="mb-1 font-bold dark:text-white">{{ item.title }}</p>
                                </a>

                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <RouterLink @click="enableNavbar" class="hover:underline hover:underline-offset-4 py-4"
                :class="{ 'underline underline-offset-4': $route.path === '/about' }" to="/about">About Us</RouterLink>
            <RouterLink @click="enableNavbar" class="hover:underline hover:underline-offset-4 py-4"
                :class="{ 'underline underline-offset-4': $route.path === '/privacypolicy' }" to="/privacypolicy">Privacy
                Policy</RouterLink>
            <RouterLink @click="enableNavbar" class="hover:underline hover:underline-offset-4 py-4"
                :class="{ 'underline underline-offset-4': $route.path === '/termandcondition' }" to="/termandcondition">
                Terms and Conditions</RouterLink>
            <RouterLink @click="enableNavbar" class="hover:underline hover:underline-offset-4 py-4"
                :class="{ 'underline underline-offset-4': $route.path === '/contact' }" to="/contact">Contact Us
            </RouterLink>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isNavbarEnabled: false,
            activeColor: 'red',
            fontSize: 30,
            isDropdownOpen: false,
            dropdownItems: [
                { title: 'Audio Transcription', description: 'Suspendisse in volutpat massa. Nulla facilisi.', Asect: '#AudioTranscription' },
                { title: 'Audio Data Collection', description: 'Sed aliquet diam orci, nec ornare metus semper sed.', Asect: "#AudioDataCollection" },
                { title: 'Data Evaluation', description: 'Integer volutpat ornare erat sit amet rutrum.', Asect: "#DataEvaluation" },
                { title: 'Document Translation', description: 'Mauris id congue metus. Fusce pellentesque.', Asect: "#DocumentTranslation" },
                { title: 'Video Translation', description: 'Ut vulputate est non quam dignissim elementum.', Asect: "#VideoTranslation" },
            ],
        };
    },
    mounted() {
        // Set initial value of isNavbarEnabled based on screen size
        this.checkScreenSize();

        // Listen for window resize events to update isNavbarEnabled dynamically
        window.addEventListener('resize', this.checkScreenSize);
    },
    // beforeUnmount() {
    //     // Remove window resize event listener to avoid memory leaks
    //     window.removeEventListener('resize', this.checkScreenSize);
    // },
    methods: {
        checkScreenSize() {
            // Adjust this breakpoint based on your design
            this.isNavbarEnabled = window.innerWidth >= 1024; // Assuming 1024px is the breakpoint for lg screens
        },
        toggleDropdown() {
            console.log(`Toggle Clicked`);
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        enableNavbar() {
            console.log("Bool ----> ", this.isNavbarEnabled);
            this.isNavbarEnabled = !this.isNavbarEnabled;
            if(window.innerWidth >= 1024){

                this.checkScreenSize();
            }
            console.log("Bool after----> ", this.isNavbarEnabled);

        },

        handleDropdownItemClick(item) {
            // Check if the current route is the "Services" page
            if (this.$route.name === 'services') {
                // Set a flag to indicate that a scroll is pending
                this.$root.scrollPending = true;

                // Scroll to the section defined by Asect
                this.$nextTick(() => {
                    const targetElement = document.querySelector(item.Asect);
                    if (targetElement) {
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                    }
                });
            } else {
                // If not on the "Services" page, navigate to it with the appropriate hash
                this.$root.scrollPending = true;

                this.$router.push({ name: 'services', hash: item.Asect });
            }
        },

    },


};

</script>

<!-- <template>
    <div class="flex items-center justify-between px-4 py-2 bg-[hsla(0,0%,0%,0.95)] text-white sticky top-0 z-10">
        <div class="flex items-center">
            <img class="w-10 h-10 mr-4" src="../assets/logo2-.png" alt="Logo">
            <span class="hidden md:block">Company Name</span>
        </div>
        <div class="hidden md:flex items-center">
            <RouterLink class="hover:underline hover:underline-offset-4 py-4" :to="{ path: '/' }">Home</RouterLink>
            <RouterLink class="hover:underline hover:underline-offset-4 py-4" :to="{ path: '/about' }">About Us</RouterLink>
            <RouterLink class="hover:underline hover:underline-offset-4 py-4" :to="{ path: '/privacypolicy' }">Privacy
                Policy</RouterLink>
            <RouterLink class="hover:underline hover:underline-offset-4 py-4" :to="{ path: '/termandcondition' }">Terms and
                Conditions</RouterLink>
            <RouterLink class="hover:underline hover:underline-offset-4 py-4" :to="{ path: '/contact' }">Contact Us
            </RouterLink>
        </div>
        <button @click="toggleDropdown" class="md:hidden">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clip-rule="evenodd"></path>
            </svg>
        </button>
    </div>
    <div class="md:hidden" :class="{ 'block': isDropdownOpen }" style="background-color: rgba(0, 0, 0, 0.8);">
        <div class="container mx-auto px-4 py-2">
            <RouterLink class="block px-4 py-2 text-white hover:bg-gray-700" :to="{ path: '/services' }">Services
            </RouterLink>
            <RouterLink class="block px-4 py-2 text-white hover:bg-gray-700" :to="{ path: '/about' }">About Us</RouterLink>
            <RouterLink class="block px-4 py-2 text-white hover:bg-gray-700" :to="{ path: '/privacypolicy' }">Privacy Policy
            </RouterLink>
            <RouterLink class="block px-4 py-2 text-white hover:bg-gray-700" :to="{ path: '/termandcondition' }">Terms and
                Conditions</RouterLink>
            <RouterLink class="block px-4 py-2 text-white hover:bg-gray-700" :to="{ path: '/contact' }">Contact Us
            </RouterLink>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            isDropdownOpen: false,
        };
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
    },
};
</script> -->
  
  
  
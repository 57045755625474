import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import TermAndConditionView from '../views/TermAndConditionView.vue'
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'
import ServiceView from '../views/ServiceView.vue'





const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter(to, from, next) {
      // Always scroll to the top of the page before entering the route
      window.scrollTo(0, 0);
      next();
    },
  },
  {
    path: '/services',
    name: 'services',
    component: ServiceView,
    // beforeEnter(to, from, next) {
    //   // Always scroll to the top of the page before entering the route
    //   window.scrollTo(0, 0);
    //   next();
    // },
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    beforeEnter(to, from, next) {
      // Always scroll to the top of the page before entering the route
      window.scrollTo(0, 0);
      next();
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    beforeEnter(to, from, next) {
      // Always scroll to the top of the page before entering the route
      window.scrollTo(0, 0);
      next();
    },
  },
  {
    path: '/termandcondition',
    name: 'termandcondition',
    component: TermAndConditionView,
    beforeEnter(to, from, next) {
      // Always scroll to the top of the page before entering the route
      window.scrollTo(0, 0);
      next();
    },
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: PrivacyPolicyView,
    beforeEnter(to, from, next) {
      // Always scroll to the top of the page before entering the route
      window.scrollTo(0, 0);
      next();
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
//   scrollBehavior(to, from, savedPosition) {
//     if (to.hash) {
//         // If the route has a hash, scroll to the element with that id
//         return { selector: to.hash };
//     } else if (savedPosition) {
//         // If a saved position exists, use it
//         return savedPosition;
//     } else {
//         // Otherwise, scroll to the top of the page
//         return { x: 0, y: 0 };
//     }
// },
})

export default router

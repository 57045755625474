<template>
   
    <div class="container border-b-2 my-12 mb-16 pt-12 mx-auto md:px-10">
      
      <section class="mb-16">
        <div class="flex justify-center">
          <div class="max-w-[700px] text-center">
            <h2 class="mb-6 text-3xl font-bold">Why Us?</h2>
            <p class="mb-16 text-slate-500 dark:text-slate-600">
              Choose Cerea Intelligence for a transformative journey in online project development. We stand out with a dedicated team, client-centric approach, and a proven track record of turning visions into reality. Our commitment to quality, agility, and innovation sets us apart.
            </p>
          </div>
        </div>
  
        <div class="grid gap-x-6 md:grid-cols-2 lg:grid-cols-4 xl:gap-x-12 border rounded-xl p-6 ">
          <div class="my-6" v-for="(feature, index) in strenghts" :key="index">
            <div class="flex">
              <div class="shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  class="mr-3 h-5 w-5 text-green-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-2 grow">
                <p class="mb-1 font-bold">{{ feature.title }}</p>
                <p class="text-slate-500 dark:text-slate-500 ">{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
 
</template>
  
<script>
  export default {
    data() {
      return {
        strenghts: [
          {
            title: 'Digital Evolution Expertise',
            description: 'Innovative Solution Expertise',
          },
          {
            title: 'Bespoke Solutions, Customized Approaches',
            description: 'Client-Centric, Cutting-Edge Expertise',
          },
          {
            title: 'Audio Mastery, Efficient Data Handling',
            description: 'Precision in Transcription, Collection',
          },
          {
            title: 'Global Data Services',
            description: 'Comprehensive, Translation, Client-Focused',
          },
          {
            title: 'Relentless Excellence, 24/7 Support',
            description: 'High-Quality, Client Satisfaction, Always-On',
          },
          {
            title: 'Seamless Document Translation',
            description: 'Cross-Border Communication, Global Reach',
          },
          {
            title: 'Video Translation Excellence',
            description: 'Multimedia Solutions, Language Accessibility',
          },
          {
            title: 'Passionate Team, Limitless Possibilities',
            description: 'Dedicated, Creative, Endless Innovation',
          },
        ],
      };
    },
  };
  </script>

  